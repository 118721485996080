@import 'styles/variables';

#ds-banner {
    .banner-box {
        min-height: 50px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 20px auto auto;
        align-items: center;
        padding: 10px 30px;

        img {
            width: 20px;
            height: 20px;
        }

        >span {
            padding-left: 10px;
        }

        .text-large.cursor-pointer {
            justify-self: end;
        }
    }

    .banner {
        min-height: 50px;
    }

    &.default-background {
        background: $slategrey;
    }

    &.warning-background {
        background: rgba(97, 90, 83, 1);
    }

    &.error-background {
        background: rgba(77, 60, 78, 1);
    }

    .dot-container {
        margin-left: -5px;
    }
}
