@import 'styles/variables';
@import 'styles/mixins';

#ds-authenticate-form {
    $labelPosition: -82px;
    border-radius: 4px;
    height: 100%;

    button {
        padding: unset;
        border: unset;
        background-color: transparent;
    }

    .ds-input--error {
        background: rgba(245, 18, 18, 0.05) !important;
        border: 0 !important;
        border-radius: 4px !important;
    
        .form-file-text {
            color: rgba(253, 74, 74, 1) !important;
        }
    
        .mdc-text-field,
        .mdc-text-field__input,
        .mdc-select .mdc-text-field--input,
        .mdc-select__selected-text {
            color: $menu-purple !important;
        }
    
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
            border-color: transparent !important;
        }
    }
    
    .ds-input--warning {
        background: rgba(254, 215, 102, 0.05) !important;
        border: 0 !important;
        border-radius: 4px !important;
    
        .form-file-text {
            color: rgba(253, 215, 102, 1) !important;
        }
    
        .mdc-text-field,
        .mdc-text-field__input,
        .mdc-text-field__input::placeholder,
        .mdc-select .mdc-text-field--input,
        .mdc-select__selected-text {
            color: rgba(255, 255, 255, 0.75) !important;
        }
    
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
            border-color: transparent !important;
        }
    }
    
    .ds-input--success {
        background: rgba(0, 180, 105, 0.05) !important;
        border: 0 !important;
        border-radius: 4px !important;
    
        .form-file-text {
            color: rgba(57, 226, 157, 1) !important;
        }
    
        .mdc-text-field,
        .mdc-text-field__input,
        .mdc-select .mdc-text-field--input,
        .mdc-select__selected-text {
            color: $menu-purple;
        }
    
        .mdc-text-field__input:focus::placeholder {
            color: transparent !important;
        }
    
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
            border-color: transparent !important;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        background-color: $palegray;
        transition: background-color 5000s ease-in-out 0s;
        font-family: $ds-home !important;
        font-size: 1rem !important;
        z-index: inherit;
        -webkit-box-shadow: 0 0 0 30px $palegray inset !important;
        box-shadow: 0 0 0 30px $palegray inset !important;
    }

    .ds-input--error {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 30px transparent  inset !important;
            box-shadow: 0 0 0 30px  transparent inset !important;
        }
    }

    .ds-input--success {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active,
        input:-webkit-autofill::first-line {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 30px transparent inset !important;
            box-shadow: 0 0 0 30px transparent inset !important;
        }
    }

    .progress-line {
        height: 3px;
        left: 2px;
        top: 62px;
    }

    .title {
        font-size: 40px;
        margin-bottom: 40px;
        letter-spacing: 1.28px;
        line-height: 47px;
        color: $sign-up-blue !important;

        @media (max-width: 1280px) {
           font-size: 35px;
        }

        @media (max-width: $phone) {
            font-size: 30px;
            margin-bottom: 38px;
        }
    }

    .heading {
        font-weight: 500;
        margin-bottom: 11px;
        letter-spacing: 0.014px;
    }

    .text-auth {
        color: $black;
    }

    .text-auth-regular {
        font-size: $regular;

        @media (max-width: $desktop) {
            font-size: $small;
        }
    }

    .link-auth {
        color: $ds-purple !important;

        &:hover {
            text-decoration: underline !important;
        }
    }
    
    .input-password input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $inputgray inset;
        -webkit-box-shadow: 0 0 0px 1000px $inputgray inset;
    }

    .text-underline {
        font-weight: 400;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .mdc-text-field__input {
        padding-right: 30px;
    }
    
    .link-click {
        color: $sign-up-blue !important;
    }

    .link-click:hover {
        color: rgba(57, 226, 157, 0.75);
        text-decoration: underline;
    }

    .circle-icon {
        height: 16px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 11px;
        top: 12px;
    }

    .sign-in-icons,
    .sign-up-icons {
        width: 30px;
        height: 30px;
    }

    .options-container {
        margin: 40px 0;

        @media (max-width: $phone) {
            margin: 20px 0;
        }
    }

    .websites-container {
        margin: 40px 0 10px;

        @media (max-width: $phone) {
            margin: 20px 0 10px 0;
        }
    }

    .acckings-website {
        margin-inline: $regularPad;
    }

    .chicksx-website {
        margin-left: $regularPad;
    }

    .gamertotal-website {
        margin-right: $regularPad;
    }

    ds-touch-target {
        &:nth-child(1) .auth-opt img::before,
        &:nth-child(2) .auth-opt img::before {
            @include square(0);
            visibility: hidden;
            opacity: 0;
        }

        &:nth-child(1) .auth-opt img::before {
            content: url("/static/icons/google-hover.svg");
        }
    
        &:nth-child(2) .auth-opt img::before {
            content: url("/static/icons/apple-hover.svg");
        }
    
        &:nth-child(3) .auth-opt img::before {
            content: url("/static/icons/discord-hover.svg");
        }

        &:nth-child(2) .auth-opt {
            margin-inline: $regularPad;
        }     
    
        &:nth-child(1) .auth-opt:hover img {
            content: url("/static/icons/google-hover.svg");
        }

        &:nth-child(2) .auth-opt:hover img {
            content: url("/static/icons/apple-hover.svg");
        }

        &:nth-child(3) .auth-opt:hover img {
            content: url("/static/icons/discord-hover.svg");
        }
    }

    .register-paragraph {
        width: 27.5vmin;
        
        @media (width <=1279px) {
            width: 276px;
            margin-top: 40px !important;
        }
        
        @media (width <=$phone) {
            width: 100%;
        }
    }

    .social-media-sign-up-icons {
        margin-top: 30px;
    }

    .chicksgold-website:hover img {
        content: url("/static/icons/chicksgold-hover.svg");
    }

    .chicksx-website:hover img {
        content: url("/static/icons/chicksx-hover.svg");
    }

    .acckings-website:hover img {
        content: url("/static/icons/acckings-hover.svg");
    }

    .gamertotal-website:hover img {
        content: url("/static/icons/gamertotal-hover.svg");
    }

    .divicasales-website:hover img {
        content: url("/static/icons/divicasales-hover.svg");
    }

    .chicks-group-label,
    .or-label,
    .all-accounts-label {
        font-size: 12px !important;
    }

    .all-accounts-label {
        margin-top: 10px;
    }

    .chicks-group-label:before,
    .chicks-group-label:after,
    .or-label:before,
    .or-label:after {
        content: "";
        background-color: $black;
        position: absolute;
        width: 72px;
        height: 1px;
        top: 8px;
    }

    .chicks-group-label:before,
    .or-label:before {
        left: $labelPosition;
    }

    .chicks-group-label:after,
    .or-label:after {
        right: $labelPosition;
    }

    #login-container {
        & #username-input {
            margin-bottom: 20px;
        }
        
        & #password-input {
            margin-bottom: 20px;
        }

        & #token-input {
            margin-bottom: 20px;
        }

        .spin-loader {
            margin: 20px 0 15px 0;
        }
    }

    #register-container {
        & #register-username-input {
            margin-bottom: 20px;            
        }

        & #register-password-input {
           margin-bottom: 20px;
        }
    }

    #reset-container {
        .confirm-pwd-container {
            margin-top: 20px;
        }
    }

    @media (max-width: 1280px) {
        background-color: transparent;

        .heading{
            color: $white;
        }

        .link-auth {
            color: $sign-up-blue !important;
        }

        .text-small {
            font-size: 12px !important;
        }

    }

    .text-small {
        font-size: $small;
        letter-spacing: 0.56px;
    }

    @media (max-width: $desktop) {
    
        .text-small {
            font-size: $small !important;
        }
    
        .text-regular {
            font-size: $small !important;
        }
    }

    @media (max-width: $phone) {

        .register-paragraph {
            padding-left: 50px;
            padding-right: 50px;
        }

        #register-container {
            & #register-username-input {
                margin-bottom: 15px;            
            }
            
             & #register-password-input {
                 margin-bottom: 15px;
             }
        }

        #login-container {
            & #username-input {
                margin-bottom: 15px;
            }

            & #password-input {
                margin-bottom: 15px;
            }
    
            & #token-input {
                margin-bottom: 15px;
            }
        }
    }

    .spinner {
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--textarea) {
        height: 50px;
    }
    
    .text-regular {
        line-height: 16px;
        letter-spacing: 0.133px;
        color: $black
    }

    .margin-question-have-account {
        margin: 10px 0 30px 0 !important;
    }

    .mt-10px {
        margin-top: 10px !important;
    }
}
