@import 'styles/variables';

#ds-tags {
    max-width: 922px;
    max-height: 25px;
    margin: 40px 0 -76px;

    @media (max-width: $desktop) {
        max-width: 731px;
        max-height: unset;
        height: 69px;
        margin: 30px 0 0;
        overflow: auto hidden;
    }

    @media (max-width: $phone) {
        max-width: 334px;
        height: 75px;
        margin: 10px 0 0;
    }

    .tags-list {
        list-style: none;
        margin: 0;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: $desktop) {
            padding-top: 10px;
            padding-bottom: 22px;
        }

        @media (max-width: $phone) {
            padding: 17px 0;
        }
    }

    .tag-option {
        color: $dsgray-text;
        float: left;
        line-height: 19px;            
        letter-spacing: 0.448px;
        white-space: nowrap;

        &:hover,
        &.active {
            color: $lilac;
        }

        @media (max-width: $desktop) {
            &:not(:last-of-type) {
                margin-right: 52px;
            }
        }
    }
}
