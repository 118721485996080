@import 'styles/variables';

#ds-banner-top {
    margin-top: 0;
    height: 422px;
    background-image: url("/static/images/banner-top.png");
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: $megascreen) {
        height: 442px;
    }

    @media (max-width: $desktop) {
        height: 306px;
    }

    @media (max-width: $phone) {
        height: 215px;
    }

    .text-banner {
        margin-top: 197px;

        @media (max-width: $desktop) {
            margin-top: 124px;
            width: 583px;
        }

        @media (max-width: $phone) {
            margin-top: 60px;
            width: 90%;
        }
    }

    .title-banner {
        font-size: $hometitlesize;
        line-height: 84px;
        letter-spacing: 1.28px;

        p {
            margin-bottom: 8px !important;

            @media (max-width: $desktop) {
                margin-bottom: 14px !important;
            }

            @media (max-width: $phone) {
                margin-bottom: 0 !important;
            }
        }

        @media (max-width: $desktop) {
            height: 48px;
            font-size: $hometitletablet;
            line-height: 48px;
        }

        @media (max-width: $phone) {
            height: 33px;
            line-height: 36px;
            margin-top: -5px;
            font-size: $extralarge;
            position: relative;
            top: 10%;
        }
    }

    .cursor-default {
        cursor: default;
    }

    .subtitle-banner {
        font-size: $huge;
        height: 72px;
        line-height: 42px;
        max-width: 1172px;

        @media (max-width: $desktop) {
            margin-top: 12px;
            font-size: $medium;
            line-height: 24px;
        }

        @media (max-width: $phone) {
            height: 33px;
            font-size: $regular;
            line-height: 21px;
        }
    }
}

