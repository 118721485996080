@import 'styles/variables';

#checkout-form {
    .form-control {
        border-radius: 4px;
        color: $ds-home;
        height: 50px;
    }

    .helper-text {
        display: block;
        color: rgba(220, 53, 69, 1);
        font-size: $regular;
        height: 12px;

        @media (max-width: $desktop) {
            font-size: $small;
        }
    }

    #card-logo {
        position: absolute;
        bottom: 12px;
        right: 15px;
        z-index: 10;
    }

    .credit-card-logo {
        margin-bottom: 3px;
    }

    .card-details {
        background: $hoverbackground;
        border-radius: 3px;
        color: $ds-home;
        height: 50px;
        margin-bottom: 2.5rem;
        margin-top: 1.8rem;
    }

    .card-img {
        max-width: 35px !important;
    }
	
    .update-button {
        height: 50px;
        margin-top: 2.8rem;
    }

    .expiry-soon-text, .expiry-text {
        margin-top: 14px;
        width: 73px;
        margin-left: -53px;

        @media (max-width: $desktop) {
            margin-left: -34px;
        }

        @media (min-width: $tablet) and (max-width: $desktop) {
            margin-left: -8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 50px;
        }

        @media (max-width: $tablet) {
            text-align: end;
        }
    }

    .expiry-text {
        margin-left: -49px;
        width: 68px;

        @media (max-width: $desktop) {
            margin-left: -29px;
        }
    } 

    .card-last-4, .expiry-soon-text, .expiry-text, .update-expiry, .update-expiry-v2 {
        font-family: 'Roboto' !important; //temporary font type
    }

    .card-last-4 {
        width: 121px;
    }

    .update-expiry {
        margin-left: -19px;
        width: 39px;

        @media (min-width: $widescreen) and (max-width: 1252px) {
            margin-left: -15px;
        }

        @media (max-width: $desktop) {
            margin-left: 2px;
        }

        @media (max-width: $tablet) {
            margin-left: unset;
            text-align: end;
        }
    }

    .card-full-name {
        @media (min-width: $desktop) {
            margin-left: 4px;
            height: 13px;
        }
    }

    .update-expiry-v2 {
        margin-left: -19px;
        width: 39px;
        
        @media (min-width: $widescreen) and (max-width: 1252px) {
            margin-left: -16px;
        }

        @media (max-width: $desktop) {
            margin-left: 2px;
        }

        @media (max-width: $tablet) {
            margin-left: unset;
            text-align: end;
        }
    }

    .mdc-text-field__input {
        padding-right: 30px;
    }

    .exp-date-padding > input {
        padding-right: 10px !important;
    }

    .text-regular {
        color: $ds-home;
    }

    .text-purple {
        color: $ds-home-button;
    }

    .text-white {
        color: $white !important;
    }

    .static-width {
        width: 177px !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        transition: background-color 5000s ease-in-out 0s;
        z-index: inherit;
        -webkit-box-shadow: 0 0 0 30px transparent  inset !important;
        box-shadow: 0 0 0 30px  transparent inset !important;
    }
}
