@import 'styles/variables';

#ds-account-type {
    .icon {
        display: inline-block;
        width: 1em;
        height: 1em;
    }

    .account-type {
        font-weight: bold;
        margin-left: 5px;
        
        &.in-house {
            color: $yellow;

            &::after {
                content: " In-House"
            }
        }

        &.resale {
            color: $green;

            &::after {
                content: " Resale"
            }
        }
    }
}
