@import 'styles/variables';

#ds-blog-navigator {
    background-color: $white;
    border: 0;
    border-radius: 4px;
    box-sizing: border-box;
    width: 361px;
    height: 622px;

    @media (max-width: $widescreen) {
        width: 608px;
    }

    @media (max-width: 645px) {
        width: 335px;
        height: 576px;
    }

    .blog-image {
        object-fit: cover;
        border-radius: 4px;
        width: 52px;
        height: 59px;
         
    }

    .no-image-blog-navigator {
        font-size: 3.4rem !important;
    }

    .text-settings-title span {
        font-size: $homecardhuge;

        @media (max-width: $widescreen) {
            font-size: $largeplusplus;
        }

        @media (max-width: $authphone) {
            font-size: $large;
        }
    }

    a {
        font-size: $medium;
    }

    .title-box {
        background-color: $white;
        font-size: $homecardhuge;
        padding-top: 27px;
        padding-bottom: 27px;

        @media (max-width: $widescreen) {
            font-size: $largeplusplus;
            padding-top: 43px;
            padding-bottom: 29px;
        }

        @media (max-width: 645px) {
            font-size: $large;
            padding-top: 40px;
            padding-bottom: 23px;
        }
    }

    .divisor-title {
        width: 64px;
        height: 5px;
        margin-top: 18px;

        @media (max-width: $widescreen) {
            margin-top: 22px;
        }

        @media (max-width: 645px) {
            margin-top: 20px;
        }
    }

    .content-box {
        width: 315px;
        padding-top: 27px;
        padding-bottom: 27px;
        line-height: 2.06;
        
        a {
            color: $ds-home;
            font-weight: regular;
            text-decoration: none;
        }

        @media (max-width: $widescreen) {
            width: 508px;
        }

        @media (max-width: 645px) {
            width: 297px;
        }
    }

    .top-line {
        border-top: solid 1px rgba(59, 60, 81, 0.1);
    }

    .btn {
        align-items: center;
        display: flex;
        height: 50px;
        white-space: nowrap;
    }

    .btn:focus {
        box-shadow: none !important;
        outline: none !important;
    }

    .alt-customer-dropdown-icon-down {
        position: absolute;
        right: 43px;
        transform: rotate(0deg);
        transition: 0.3s ease;
    }

    .show {
        .alt-customer-dropdown-icon-down {
            transform: rotate(180deg);
            transition: 0.3s ease;
        }
    }

    .dropdown-menu {
        background-color: $white;
        border: 0;
        color: $white;
        padding: 0;
    }

    .dropdown-item {
        text-overflow: ellipsis;
        overflow: hidden;
        height: 48px;

        &:hover {
            color: white;
            background-color: rgba(87, 98, 213, .1);
        }
    }

    .dropdown-item:active {
        background-color: $dropdownprimarybackground;
    }

    .dropdown:hover {
        background-color: rgba(76, 96, 133, .3);
        color: $white;
    }

    .blog-blurb {
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        @media (max-width: $tablet) {
            -webkit-line-clamp: 1;
        }
    }
}
