@import 'styles/variables';

#ds-accordion-menu {
    .mdc-expandable {
        background-color: transparent;
        box-shadow: none !important;
        border-bottom: 1px solid $menu-white;

        &:hover {
            background: $menu-purple-hover;
        }
    }

    .text-regular {
        color: $white;
    }

    .mdc-expandable__dropdown-icon a {
        color: $white !important;
        font-size: 50px !important;
    }

    .mdc-expandable .mdc-expandable__content-container .mdc-expandable__content {
        padding: 9px 16px 9px;
        background: $menu-purple-hover;
        cursor: pointer;
    }

    .mdc-expandable .mdc-expandable__dropdown-icon {
        fill: $white !important;
    }

    .mdc-expandable.mdc-expandable--open {
        margin: unset;
    }

    .mdc-expandable__content-container {
        background-color: $primarybackground;
    }

    .accordion-content {
        text-transform: capitalize;
        font-size: $small;
        padding: 8px 0;
    }

    .accordion-sub-content {
        text-transform: capitalize;
        font-size: $small;
        padding: 8px 15px;
    }

    .mdc-expandable__content-container-sub {
        background-color: $primarybackground;
    }

    .accordion-no-link {
        cursor: pointer;
    }

    .expandable-nested.accordion-content {
        padding: 8px 8px;
    }

    .mdc-expandable__header {
        height: 50px !important;
    }

    .faq-arrow-icon {
        transform: rotate(0deg);
    }

    .mdc-expandable__header--open .faq-arrow-icon {
        transform: rotate(180deg);
    }

    .no-expandable {
        .mdc-expandable__dropdown-icon {
            display: none;
        }
    }

    .no-expandable .mdc-expandable__content-container .mdc-expandable__content {
        display: none !important;
    }

    .currency-item {
        &:hover {
            color: $white;
        }
    }
}
