@import 'styles/variables';

#ds-blog-preview {
    position: relative;
    background-color: transparent;
    box-sizing: border-box;
    width: 90%;
    height: 420px;
    margin: 0;

    @media (max-width: 750px) {
        width: 100%;
        height: 400px;
    }
    
    .blog-image {
        object-fit: cover;
        border-radius: 6px;
        max-width: 100%;
        height: 200px;
        min-height: 200px;
        min-width: 100%;

        @media (max-width: $extrascreen) {
            height: 180px;
            min-height: 180px;
        }

        @media (max-width: 750px) {
            height: 160px;
            min-height: 160px;
        }
    }

    .date {
        color: $dsgray-text;
        font-size: $medium;
        text-transform: uppercase;
        text-align: left;
    }

    .blog-title {
        font-size: $extralargeplus;
        text-align: left;
        margin-bottom: 22px;
        margin-top: 20px;
        a {
            color: $ds-home;
        }

        @media (max-width: $extrascreen) {
            font-size: $large;
        }

        @media (max-width: $phone) {
            font-size: $medium;
        }
    }

    .content {
        border-top: none;
        border-radius: 0 0 4px 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        margin-right: auto;
    }

    .read-more-button {
        position: absolute;
        justify-content: start;
        align-items: center;
        flex-grow: 1;
        left: 23px;
        bottom: 40px;

        @media (max-width: 850px) { 
            bottom: 30px;
        }
    }

    .blog-blurb {
        width: 100%;
        font-size: $largeplusplus;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

        @media (max-width: $extrascreen) {
            font-size: $medium;
        }

        @media (max-width: $phone) {
            font-size: $regular;
        }
    }

    .read-more {
        padding: auto;
        text-align: center;
        width: 180px;
        height: 50px;
        background-color: transparent;
        border: 1px solid $ds-home-button;
        color: $ds-home-button;
        border-radius: 4px;
        transition: 0.4s;
        font-size: $regular;
        text-transform: uppercase;

        &:hover {
            background-color: $ds-home-button;
            color: $white;
        }
    }
}
