@import 'styles/variables';
@import 'styles/mixins';

#ds-posts-cards {
    .card-deck {
        display: grid;
        gap: 38px 20px;
        grid-template-columns: repeat(3, 1fr);
        
        @media (max-width: $desktop) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: unset;
            gap: 20px 16px;
        }

        @media (max-width: $phone) {
            grid-template-columns: repeat(1, 1fr);       
            gap: 20px;
        }
    }

    .card {
        height: 390px;
        max-width: 294px;
        border-radius: 18px;
        overflow: hidden;
        border: none;
        box-shadow: 0px 1px 8px 0px rgba(87, 98, 213, 0.12);

        @media (max-width: $desktop) {
            max-width: 263px;
            height: 376px;
        }

        @media (max-width: $phone) {
            max-width: 334px;
            height: 390px;
        }

        img {
            height: 100px;
            object-fit: cover;
            margin-bottom: 18px;
            border-radius: 18px 18px 0 0;
        }

        .no-image {
            height: 100px;
            @include flex-center();
            background-color: $white;
            margin-bottom: 18px;

            span {
                font-size: $extraextrahuge;
            }

            @media ($tablet <= width <= $desktop) {
                margin-bottom: 10px;
            }
        }

        .card-body {
            padding: 0 20px !important;

            .card-title {
                font-size: $large;
                color: $softblack;
                width: 100%;
                margin-bottom: -8px;
                line-height: 32px;

                @media ($tablet <= width <= $desktop) {
                    font-size: $regular;
                    line-height: normal;
                    margin-bottom: 0;
                }
            }

            .card-text {
                font-size: $regular;
                color: $mediumblack;
                height: 100px;
                letter-spacing: 0.1px;
                line-height: 24px;
                @include truncate-text(4);

                p {
                    text-align: left !important;

                    @media (width < $tablet) {
                        max-width: 254px;
                        line-height: 24px;
                        margin: 0 !important;
                        padding-bottom: 4px;
                    }
                }

                @media (max-width: $tablet) {
                    margin-bottom: -12px;
                    color: $menu-purple;
                }
            }

            h2 {
                font-size: $regular;
            }

            h5 {
                width: fit-content;
                max-width: 254px;

                @media (width <= $desktop) {
                    max-width: 223px;
                }

                @media (width <= $phone) {
                    max-width: 294px;
                }
            }

            .card-footer {
                background: transparent;
                border-color: $divisor-gray;
                padding: 0;
                margin-top: 15px;
                color: $darkergray;

                p, a {
                    margin-top: 17px;
                    margin-bottom: 0;
                    line-height: normal;
                }

                a {
                    color: $sign-up-blue;
                    font-size: $regular;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }

            .card-author {
                color: $darkergray;
                line-height: normal;

                &:hover {
                    text-decoration: underline !important;
                }
            }

            .footer-blog {
                position: absolute;
                bottom: 20px;
                left: 20px;
                width: calc(100% - 40px);
            }

            .tag-label {
                font-weight: 300;
                font-size: $small;
                color: $sign-up-blue;
                cursor: pointer;
                margin-bottom: 10px;
                max-width: max-content;

                &:hover {
                    text-decoration: underline !important;
                }

                @media (max-width: $tablet) {
                    margin-bottom: 14px;
                }

                @media (max-width: $phone) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
