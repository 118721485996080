@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#ds-sticky-post {
    width: 100%;
    height: auto;

    .blog-image {
        margin-bottom: v.$bigPad;
        width: 100%;
        max-height: 300px;
        border-radius: 8px;

        @media (width <= v.$desktop) {
            height: 250px;
            margin-bottom: 20px;
        }

        @media (width <=  v.$phone) {
            height: 150px;
        }
    }

    .content-box {
        width: 100%;

        a {
            font-size: v.$extrahugetitle;
            color: v.$darkergray;
            text-decoration: none;
        }

        @media (width <= v.$tablet) {
            width: 728px;
        }
    }

    .content {
        width: 100%;
        font-size: v.$medium;
        color: v.$darkergray;
        line-height: 32px;
        max-height: 426px;
        margin-top: 12px;

        p {
            margin-bottom: 0;
        }

        @media (width < v.$desktop) {
            margin-top: 15px;
            font-weight: 400;
            font-size: v.$regular;
        }

        @media (width <= v.$phone) {
            margin-left: 195px;
            width: 334px;
        }
    }

    .read-more {
        width: 146px;
        height: 45px;
        border-radius: 6px;
        transition: 0.4s;
        background: v.$sign-up-blue;
        border-color: transparent;
        font-size: 16px !important;
        color:white;

        &:hover {
            background-color: rgba(110, 86, 255, 1);
        }
    }

    .blog-title {
        line-height: 40px;

        @media (width <= v.$tablet) {
            font-size: v.$huge !important;
            font-weight: 900;
            line-height: 24px;
        }

        @media (width <= v.$phone) {
            margin-left: 195px;
            width: 334px;
        }
    }

    .content-sticky {
        @media (width <= v.$phone) {
            margin-left: 0;
        }
    }

    .inside-content {
        @include m.truncate-text(4);
        margin-block: 12px;

        @media (width <= v.$tablet) {
            margin-block: 15px;
        }
    }

    %shared-label {
        font-weight: 400;
        font-size: v.$regular;
        color: v.$darkpurple;
        cursor: pointer;
    }

    .tag-label {
        @extend %shared-label;
        float: left;
        margin-bottom: 2px;
        margin-right: 6px;

        &:hover {
            text-decoration: underline !important;
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    a.author-label {
        @extend %shared-label;
        float: right;
        line-height: 14px;

        &:hover {
            text-decoration: underline !important;
        }
    }

    @media (width <= v.$desktop) {
        .blog-content p:last-child {
            margin-bottom: 5px !important;
        }
    }
}
