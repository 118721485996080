@import 'styles/variables';

#sitemap {
    margin-bottom: 132px;

    @media (max-width: $desktop) {
        margin-bottom: 12px;
    }

    .sitemap-link-content {
        width: fit-content;
        margin-right: auto;
    }

    .sitemap-link {
        mix-blend-mode: normal;
        cursor: pointer;
        display: block;
        text-decoration: unset;
        margin-bottom: 20px;
        letter-spacing: 0.448px;
    }

    .header-top {
        margin-top: 203px;

        @media (max-width: $desktop) {
            margin-top: 87px;
        }
    }

    .title-sitemap {
        max-width: 252px;
        font-size: $hometitlesize;

        @media (max-width: $desktop) {
            font-size: $extrahuge;
        }
    }

    .category-name {
        margin-bottom: 40px;

        @media (max-width: $phone) {
            margin-bottom: 20px;
        }
    }

    .category-icon {
        margin-right: 7px;
    }

    .sitemap-link:hover {
        text-decoration: underline;
    }

    .category-column {
        margin-top: 42px;

        @media (min-width: $desktop) {
            width: 21%;
        }

        @media (max-width: $desktop) {
            width: 25%;
        }

        @media (max-width: $phone) {
            width: 50%;
            margin-top: 24px;
        }
    }

    .content-padding {
        @media (max-width: $desktop) {
            padding: 0 20px 0 20px;
        }
    }

    .category-column:nth-child(n + 2) {
        @media (max-width: $desktop) {
            width: 20%;
            margin-left: 35px;
        }

        @media (max-width: $phone) {
            width: 50%;
            margin-left: 0;
        }
    }

    .category-column:nth-child(even) {
        @media (max-width: $phone) {
            width: 35%;
            margin-left: 45px;
        }
    }

    .cont-search {
        @media (max-width: $desktop) {
            max-width: 260px;
        }

        @media (max-width: $phone) {
            max-width: 334px;
        }
    }

    .search-field {
        width: 443px;
        border: 1px solid $white;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
        border-radius: 4px;

        @media (max-width: $desktop) {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    .close-icon {
        top: 12px;
        right: 10px;
    }

    .input-password.search-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
        overflow: hidden;
        padding-right: 25px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $menu-purple;
    }
}
